import { Injectable } from "@angular/core";

import { FCM } from "@capacitor-community/fcm";
import { PushNotifications } from "@capacitor/push-notifications";

@Injectable({
  providedIn: "root",
})
export class FcmService {
  constructor() {}

  async initFCM() {
    await PushNotifications.requestPermissions();
    await PushNotifications.register();

    this.getToken();
  }

  getToken() {
    FCM.getToken()
      .then((r) => {
        localStorage.setItem("fcmAPP", r.token);
        console.log(`Token ${r.token}`);
      })
      .catch((err) => console.log(err));
  }

  subscribeTo() {
    FCM.subscribeTo({ topic: "test" })
      .then((r) => alert(`subscribed to topic`))
      .catch((err) => console.log(err));
  }

  unsubscribeFrom() {
    FCM.unsubscribeFrom({ topic: "test" })
      .then(() => alert(`unsubscribed from topic`))
      .catch((err) => console.log(err));
  }

  deleteInstance() {
    FCM.deleteInstance()
      .then(() => alert(`Token deleted`))
      .catch((err) => console.log(err));
  }

  setAutoInit() {
    FCM.setAutoInit({ enabled: true }).then(() => alert(`Auto init enabled`));
  }

  isAutoInitEnabled() {
    FCM.isAutoInitEnabled().then((r) => {
      console.log("Auto init is " + (r.enabled ? "enabled" : "disabled"));
    });
  }
}
