import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

import { translateData } from "../interfaces/translate/translate";

@Injectable({
  providedIn: "root",
})
export class TranslateService {
  constructor(private http: HttpClient) {}

  addTranslate() {
    /*   var url = `${environment.api}translate`;
    this.http
      .get(url)
      .toPromise()
      .then((data: Translate) => {
        let translate = JSON.stringify(data.data);
        localStorage.setItem("translateAPP", translate);
      }); */
  }

  setCountryTranslate(value: string) {
    localStorage.setItem("translateAPPSetting", value);
  }

  manuallyTranslate() {
    var dataTranslate: translateData[] = [
      {
        id: 1,
        name: "Favorite",
        pt: "Favorito",
        en: "Favorite",
        es: "",
      },

      {
        id: 1,
        name: "Remove favorite",
        pt: "Remover favorito",
        en: "Remove favorite",
        es: "",
      },

      {
        id: 1,
        name: "Loading...",
        pt: "Carregando...",
        en: "Loading...",
        es: "",
      },
      {
        id: 1,
        name: "ALL Channels",
        pt: "Todos os canais",
        en: "ALL Channels",
        es: "",
      },
      {
        id: 1,
        name: "Favorites",
        pt: "Favoritos",
        en: "Favorites",
        es: "",
      },
      {
        id: 1,
        name: "Settings",
        pt: "Config",
        en: "Settings",
        es: "",
      },
      {
        id: 1,
        name: "Categories",
        pt: "Categorias",
        en: "Categories",
        es: "",
      },
      {
        id: 1,
        name: "List View",
        pt: "Visualização",
        en: "List View",
        es: "",
      },
      {
        id: 1,
        name: "You have no favorites added",
        pt: "Você não tem favoritos adicionados.",
        en: "You have no favorites added",
        es: "",
      },
      {
        id: 1,
        name: "Edit playlist",
        pt: "Editar playlist",
        en: "Edit playlist",
        es: "",
      },
      {
        id: 1,
        name: "Remove playlist",
        pt: "Remover playlist",
        en: "Remove playlist",
        es: "",
      },

      {
        id: 1,
        name: "Create list",
        pt: "Criar lista",
        en: "Create list",
        es: "",
      },
      {
        id: 1,
        name: "Name",
        pt: "Nome",
        en: "Name",
        es: "",
      },
      {
        id: 1,
        name: "Set a name for your list",
        pt: "Defina um nome para sua lista",
        en: "Set a name for your list",
        es: "",
      },
      {
        id: 1,
        name: "Set your List url",
        pt: "Defina a url da sua lista",
        en: "Set your List url",
        es: "",
      },
      {
        id: 1,
        name: "Set a UUID for your list",
        pt: "Defina a UUID da sua lista",
        en: "Set a UUID for your list",
        es: "",
      },
      {
        id: 1,
        name: "Confirm",
        pt: "Confirmar",
        en: "Confirm",
        es: "",
      },
      {
        id: 1,
        name: "Cancel",
        pt: "Cancelar",
        en: "Cancel",
        es: "",
      },
      {
        id: 1,
        name: "Enter a valid url",
        pt: "Digite uma URL válida",
        en: "Enter a valid url",
        es: "",
      },
      {
        id: 1,
        name: "Please enter a valid name that has more than one character",
        pt: "Digite um nome válido que tenha mais de um caractere",
        en: "Please enter a valid name that has more than one character",
        es: "",
      },
      {
        id: 1,
        name: "Loading playlist",
        pt: "Carregar playlist",
        en: "Loading playlist",
        es: "",
      },
      {
        id: 1,
        name: "Unable to connect, check your URL and if the file is really m3u/m3u8 formatted",
        pt: "Não foi possível conectar, verifique sua URL e se o arquivo está realmente no formato m3u/m3u8",
        en: "Unable to connect, check your URL and if the file is really m3u/m3u8 formatted",
        es: "",
      },
      {
        id: 1,
        name: "System returned 0 channels in your playlist, check if m3u/m3u8 file is correct, confirm your URL is correct.",
        pt: "Sistema retornou 0 canais em sua playlist, verifique se o arquivo m3u/m3u8 está correto, confirme se sua URL está correta.",
        en: "System returned 0 channels in your playlist, check if m3u/m3u8 file is correct, confirm your URL is correct.",
        es: "",
      },
      {
        id: 1,
        name: "Loading your playlist...",
        pt: "Carregar a sua playlist...",
        en: "Loading your playlist...",
        es: "",
      },
      {
        id: 1,
        name: "System UUID was successfully copied!",
        pt: "UUID do sistema foi copiado com sucesso!",
        en: "System UUID was successfully copied!",
        es: "",
      },
      {
        id: 1,
        name: "Filtered by category:",
        pt: "Filtrado pela categoria:",
        en: "Filtered by category:",
        es: "",
      },
      {
        id: 1,
        name: "No content was found for this category!",
        pt: "Não foi encontrado nenhum contéudo com está categoria!",
        en: "No content was found for this category!",
        es: "",
      },
      {
        id: 1,
        name: "Update the version of your application",
        pt: "Atualize a versão do seu aplicativo",
        en: "Update the version of your application",
        es: "",
      },
      {
        id: 1,
        name: "Click here to update!",
        pt: "CLIQUE AQUI para atualizar!",
        en: "Click here to update!",
        es: "",
      },
      {
        id: 1,
        name: "Notification",
        pt: "Notificações",
        en: "Notification",
        es: "",
      },
      {
        id: 1,
        name: "Active notifications",
        pt: "Notificações ativas",
        en: "Active notifications",
        es: "",
      },
      {
        id: 1,
        name: "Translate",
        pt: "Idioma",
        en: "Translate",
        es: "",
      },
      {
        id: 1,
        name: "Portuguese",
        pt: "Português",
        en: "Portuguese",
        es: "",
      },
      {
        id: 1,
        name: "English",
        pt: "Inglês",
        en: "English",
        es: "",
      },
      {
        id: 1,
        name: "Spanish",
        pt: "Espanhol",
        en: "Spanish",
        es: "",
      },
      {
        id: 1,
        name: "Contact",
        pt: "Contato",
        en: "Contact",
        es: "",
      },

      {
        id: 1,
        name: "To contact regarding:",
        pt: "Para entrar em contato referente:",
        en: "To contact regarding:",
        es: "",
      },
      {
        id: 1,
        name: "- Doubts,",
        pt: "- Dúvidas,",
        en: "- Doubts,",
        es: "",
      },
      {
        id: 1,
        name: "- Helps,",
        pt: "- Ajuda,",
        en: "- Helps,",
        es: "",
      },
      {
        id: 1,
        name: "- Partnerships,",
        pt: "- Parcerias,",
        en: "- Partnerships,",
        es: "",
      },
      {
        id: 1,
        name: "- Others...",
        pt: "- Outros...",
        en: "- Others...",
        es: "",
      },
      {
        id: 1,
        name: "Contact by email:",
        pt: "Entre em contato por email:",
        en: "Contact by email:",
        es: "",
      },
      {
        id: 1,
        name: "Email successfully copied!",
        pt: "Email copiado com sucesso!",
        en: "Email successfully copied!",
        es: "",
      },
      {
        id: 1,
        name: "Synchronization successful!",
        pt: "Sincronização efetuada com sucesso!",
        en: "Synchronization successful!",
        es: "",
      },
      {
        id: 1,
        name: "Search",
        pt: "Procurar",
        en: "Search",
        es: "",
      },
      {
        id: 1,
        name: "Result of",
        pt: "Resultado de",
        en: "Result of",
        es: "",
      },

      {
        id: 1,
        name: "Add",
        pt: "Adicionar",
        en: "Add",
        es: "",
      },
      {
        id: 1,
        name: "Updated",
        pt: "Atualizar",
        en: "Updated",
        es: "",
      },
      {
        id: 1,
        name: "Clear",
        pt: "Limpar",
        en: "Clear",
        es: "",
      },
      {
        id: 1,
        name: "Successfully removed!",
        pt: "Removido com sucesso!",
        en: "Successfully removed!",
        es: "",
      },
      {
        id: 1,
        name: "Successfully created!",
        pt: "Criado com sucesso!",
        en: "Successfully created!",
        es: "",
      },
      {
        id: 1,
        name: "Successfully updated!",
        pt: "Atualizado com sucesso!",
        en: "Successfully updated!",
        es: "",
      },
      {
        id: 1,
        name: "Do you want to remove this list?",
        pt: "Você deseja remover está lista?",
        en: "Do you want to remove this list?",
        es: "",
      },
      {
        id: 1,
        name: "Something went wrong, try again later.",
        pt: "Algo deu errado, tente mais tarde.",
        en: "Something went wrong, try again later.",
        es: "",
      },
      
    ];
    localStorage.setItem("translateAPP", JSON.stringify(dataTranslate));
  }
}
