import { CreateIptv, ListIPTV } from './../../modules/list-Iptv/interface/create-iptv';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MockupsService {

playlistDefault:ListIPTV[] = [
    
  {
      "service": "iptv",
      "title": "1917 LEG ",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/164543.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/164543.mp4",
      "id": "",
      "author": " 1917 LEG ",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/i0S4a7g5q35NYE2483H3lSwY0up.jpg",
      "group": "LEGENDADO"
  },
  {
      "service": "iptv",
      "title": "4K+ 5 1",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/Isadora/141917/174631",
      "url": "http://cdnrede.com:80/Isadora/141917/174631",
      "id": "",
      "author": " 4K+ 5 1",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/8KkyXE7Hd9MPOOkORxkMM6SUXAB.jpg",
      "group": "4K ULTRA HD"
  },
  {
      "service": "iptv",
      "title": "A SABIÁ SABIAZINHA",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/220584.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/220584.mp4",
      "id": "",
      "author": " A SABIÁ SABIAZINHA",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/p9iZ9OVPvr9tSqruiNgjReGvYB9.jpg",
      "group": "INFANTIL"
  },
  {
      "service": "iptv",
      "title": "A ULTIMA TENTACAO DE CRISTO",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/240208.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/240208.mp4",
      "id": "",
      "author": " A ULTIMA TENTACAO DE CRISTO",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/zAyCulPkOHA4AB4d4dK3XzUVwjt.jpg",
      "group": "RELIGIOSO"
  },
  {
      "service": "iptv",
      "title": "ANIMAIS FANTASTICOS OS SEGREDOS DE DUMBLEDORE ",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/227486.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/227486.mp4",
      "id": "",
      "author": " ANIMAIS FANTASTICOS OS SEGREDOS DE DUMBLEDORE ",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/gopGghuMtmdMviBcl9G0JfVB2RZ.jpg",
      "group": "ACAO/AVENTURA"
  },
  {
      "service": "iptv",
      "title": "AS VIÚVAS",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/174079.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/174079.mp4",
      "id": "",
      "author": " AS VIÚVAS",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/gWKs9H9AEFQQmoEUGfCdvKkHRj6.jpg",
      "group": "TERROR/SUSPENSE"
  },
  {
      "service": "iptv",
      "title": "CAUSALIDADE",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/221332.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/221332.mp4",
      "id": "",
      "author": " CAUSALIDADE",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/vqPTx3z2RVOvrIO3aJYO7avSavC.jpg",
      "group": "TERROR/SUSPENSE"
  },
  {
      "service": "iptv",
      "title": "CONTRA O RELÓGIO",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/191354.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/191354.mp4",
      "id": "",
      "author": " CONTRA O RELÓGIO",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/z6ago1etfmMOBqNx3lWxCCrzp1o.jpg",
      "group": "TERROR/SUSPENSE"
  },
  {
      "service": "iptv",
      "title": "DIAS NO CATIVEIRO",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/66140.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/66140.mp4",
      "id": "",
      "author": " DIAS NO CATIVEIRO",
      "thumb_square": "http://pbh.vc:80/images/mtZULLtnndt9BJdlsS5Kg36PjUI_small.jpg",
      "group": "TERROR/SUSPENSE"
  },
  {
      "service": "iptv",
      "title": "DIMENSÃO PROIBIDA ",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/174975.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/174975.mp4",
      "id": "",
      "author": " DIMENSÃO PROIBIDA ",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/s2s4KlXkHMXDxIRMJAkMmh2DqXa.jpg",
      "group": "TERROR/SUSPENSE"
  },
  {
      "service": "iptv",
      "title": "DÉJÀ VU",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/190634.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/190634.mp4",
      "id": "",
      "author": " DÉJÀ VU",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/oy5FT2x0JOqpKgXH9bhTytR7hbO.jpg",
      "group": "FICCAO"
  },
  {
      "service": "iptv",
      "title": "EU VI O DIABO",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/215706.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/215706.mp4",
      "id": "",
      "author": " EU VI O DIABO",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/dFGrIdZGJtxm1GG4X1liYbxyjgd.jpg",
      "group": "TERROR/SUSPENSE"
  },
  {
      "service": "iptv",
      "title": "HOMEM ARANHA: DE VOLTA AO LAR",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/15972.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/15972.mp4",
      "id": "",
      "author": " HOMEM ARANHA: DE VOLTA AO LAR",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/9Fgs1ewIZiBBTto1XDHeBN0D8ug.jpg",
      "group": "MARVEL & DC"
  },
  {
      "service": "iptv",
      "title": "INSÔNIA MORTAL",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/217711.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/217711.mp4",
      "id": "",
      "author": " INSÔNIA MORTAL",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/xD8YeHjn5pA7dkfXliQ2jGw0B5W.jpg",
      "group": "TERROR/SUSPENSE"
  },
  {
      "service": "iptv",
      "title": "MALCOLM X ",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/174531.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/174531.mp4",
      "id": "",
      "author": " MALCOLM X ",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/bZe75U5A2lCSQI1Ph57zno7rsCk.jpg",
      "group": "CLASSICO"
  },
  {
      "service": "iptv",
      "title": "MINORITY REPORT A NOVA LEI",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/190633.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/190633.mp4",
      "id": "",
      "author": " MINORITY REPORT A NOVA LEI",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/46W2XlfckBMIutQxtydJ44Ograg.jpg",
      "group": "FICCAO"
  },
  {
      "service": "iptv",
      "title": "SEM CONEXAO PARTE 2",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/217709.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/217709.mp4",
      "id": "",
      "author": " SEM CONEXAO PARTE 2",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/k5Z2DdVqtWxGm14d3F3GawHlGOV.jpg",
      "group": "TERROR/SUSPENSE"
  },
  {
      "service": "iptv",
      "title": "STAND BY ME DORAEMON 2",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/222319.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/222319.mp4",
      "id": "",
      "author": " STAND BY ME DORAEMON 2",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/7BYnS5w8pDrCUa801f2A0aubKk0.jpg",
      "group": "INFANTIL"
  },
  {
      "service": "iptv",
      "title": "SUMMER OF SOUL",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/224596.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/224596.mp4",
      "id": "",
      "author": " SUMMER OF SOUL",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/zPMSeDxHdinpbJJdXQS8IrfD1Ux.jpg",
      "group": "OSCAR 2022"
  },
  {
      "service": "iptv",
      "title": "TERROR NO ESTUDIO 666 LEG",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/232234.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/232234.mp4",
      "id": "",
      "author": " TERROR NO ESTUDIO 666 LEG",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/tcsnV2L6SZY9hvuGLEbZddTylPa.jpg",
      "group": "LEGENDADO"
  },
  {
      "service": "iptv",
      "title": "UM PLANO PERFEITO",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/174069.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/174069.mp4",
      "id": "",
      "author": " UM PLANO PERFEITO",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/rGoM7yfo1rpUocU1vlotwGZDBwd.jpg",
      "group": "ROMANCE/DRAMA"
  },
  {
      "service": "iptv",
      "title": "#NUDES",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/167950.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/167950.mp4",
      "id": "",
      "author": "#NUDES",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/m3zGn5EOqvb1CxSnkprn6K0SiV3.jpg",
      "group": "COMEDIA"
  },
  {
      "service": "iptv",
      "title": "0 ANOS DEPOIS",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/57805.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/57805.mp4",
      "id": "",
      "author": "0 ANOS DEPOIS",
      "thumb_square": "https://www.cineon.com.br/uploads/series/10000-Anos-Depois.jpg",
      "group": "FICCAO"
  },
  {
      "service": "iptv",
      "title": "007 A SERVICO SECRETO DE SUA MAJESTADE",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/230437.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/230437.mp4",
      "id": "",
      "author": "007 A SERVICO SECRETO DE SUA MAJESTADE",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/dFIX4ZDjpcGwGvOSVrWxboBZAzb.jpg",
      "group": "ACAO/AVENTURA"
  },
  {
      "service": "iptv",
      "title": "007 CONTRA A CHANTAGEM ATOMICA",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/230436.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/230436.mp4",
      "id": "",
      "author": "007 CONTRA A CHANTAGEM ATOMICA",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/ot8pEGo9Mljwkz7Ak8hv4qjBmsu.jpg",
      "group": "ACAO/AVENTURA"
  },
  {
      "service": "iptv",
      "title": "007 CONTRA GOLDFINGER",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/230435.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/230435.mp4",
      "id": "",
      "author": "007 CONTRA GOLDFINGER",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/hi8vxhCM1EzLvfF6VI7GYiGgMBH.jpg",
      "group": "ACAO/AVENTURA"
  },
  {
      "service": "iptv",
      "title": "007 CONTRA O HOMEM COM A PISTOLA DE OURO",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/230434.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/230434.mp4",
      "id": "",
      "author": "007 CONTRA O HOMEM COM A PISTOLA DE OURO",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/nJCOA4ufiHoAxofGQ3AqExEOZv3.jpg",
      "group": "ACAO/AVENTURA"
  },
  {
      "service": "iptv",
      "title": "007 CONTRA O SATANICO DR. NO",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/230433.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/230433.mp4",
      "id": "",
      "author": "007 CONTRA O SATANICO DR. NO",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/zJPZPtBqPSN73m6UmpS7uTTOZSo.jpg",
      "group": "ACAO/AVENTURA"
  },
  {
      "service": "iptv",
      "title": "007 O ESPIAO QUE ME AMAVA",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/230431.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/230431.mp4",
      "id": "",
      "author": "007 O ESPIAO QUE ME AMAVA",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/sZss61yM6uKT2KU1aGX7W8ElsPE.jpg",
      "group": "ACAO/AVENTURA"
  },
  {
      "service": "iptv",
      "title": "007 OS DIAMANTES SAO ETERNOS",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/230430.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/230430.mp4",
      "id": "",
      "author": "007 OS DIAMANTES SAO ETERNOS",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/n0UhYapEWDcrgDgKvRKHZMyv4z6.jpg",
      "group": "ACAO/AVENTURA"
  },
  {
      "service": "iptv",
      "title": "007 SEM TEMPO PARA MORRER",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/220599.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/220599.mp4",
      "id": "",
      "author": "007 SEM TEMPO PARA MORRER",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/luE0KG1rWfUptbgNtlNtL5sihyd.jpg",
      "group": "OSCAR 2022"
  },
  {
      "service": "iptv",
      "title": "007 SEM TEMPO PARA MORRER LEG",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/218670.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/218670.mp4",
      "id": "",
      "author": "007 SEM TEMPO PARA MORRER LEG",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/luE0KG1rWfUptbgNtlNtL5sihyd.jpg",
      "group": "LEGENDADO"
  },
  {
      "service": "iptv",
      "title": "007 CASSINO ROYALE",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/48466.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/48466.mp4",
      "id": "",
      "author": "007 CASSINO ROYALE",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/7b3qmLlvm2lvJ2gxsKQ42S3RTKR.jpg",
      "group": "ACAO/AVENTURA"
  },
  {
      "service": "iptv",
      "title": "007 CONTRA SPECTRE",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/48469.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/48469.mp4",
      "id": "",
      "author": "007 CONTRA SPECTRE",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/gWoUlYxVRZsjh9L9CZjrHSM2l6W.jpg",
      "group": "ACAO/AVENTURA"
  },
  {
      "service": "iptv",
      "title": "007 OPERAÇÃO SKYFALL",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/48468.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/48468.mp4",
      "id": "",
      "author": "007 OPERAÇÃO SKYFALL",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/waOFaJB4FRPyA2KylJncSG57mj0.jpg",
      "group": "ACAO/AVENTURA"
  },
  {
      "service": "iptv",
      "title": "007 QUANTUM OF SOLACE",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/48467.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/48467.mp4",
      "id": "",
      "author": "007 QUANTUM OF SOLACE",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/fV0ejyUsnqd8BzHcK13C7ZJFEa1.jpg",
      "group": "ACAO/AVENTURA"
  },
  {
      "service": "iptv",
      "title": "10 000 A C ",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/195159.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/195159.mp4",
      "id": "",
      "author": "10 000 A C ",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/yS9WxbISOCzucj7484IohnrBr5b.jpg",
      "group": "ROMANCE/DRAMA"
  },
  {
      "service": "iptv",
      "title": "10 COISAS QUE DEVERÍAMOS FAZER ANTES DE NOS SEPARAR",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/171136.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/171136.mp4",
      "id": "",
      "author": "10 COISAS QUE DEVERÍAMOS FAZER ANTES DE NOS SEPARAR",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/vtzw9PBFYir17bSXV7Dv1X6PhZ5.jpg",
      "group": "ROMANCE/DRAMA"
  },
  {
      "service": "iptv",
      "title": "10 COISAS QUE EU ODEIO EM VOCE",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/232424.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/232424.mp4",
      "id": "",
      "author": "10 COISAS QUE EU ODEIO EM VOCE",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/6WImK7UIRY7fZ0l9UwnxbP74w1p.jpg",
      "group": "COMEDIA"
  },
  {
      "service": "iptv",
      "title": "10 HORAS PARA O NATAL",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/192138.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/192138.mp4",
      "id": "",
      "author": "10 HORAS PARA O NATAL",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/zQF84E6xXSPur1gEbyKJ2P1BECp.jpg",
      "group": "ESPECIAL DE NATAL"
  },
  {
      "service": "iptv",
      "title": "10 SEGUNDOS PARA VENCER",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/57144.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/57144.mp4",
      "id": "",
      "author": "10 SEGUNDOS PARA VENCER",
      "thumb_square": "http://pbh.vc:80/images/q5nmhRu2TyLzaNnhny5XNZumdWI_small.jpg",
      "group": "ROMANCE/DRAMA"
  },
  {
      "service": "iptv",
      "title": "100 MEDOS",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/241327.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/241327.mp4",
      "id": "",
      "author": "100 MEDOS",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/o3YitLNoZsucoGrcjehaR9p45Ip.jpg",
      "group": "COMEDIA"
  },
  {
      "service": "iptv",
      "title": "100 RIFLES",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/242327.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/242327.mp4",
      "id": "",
      "author": "100 RIFLES",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/5uZQIL7XUM1PQXNX2WNK5M42KZY.jpg",
      "group": "FAROESTE"
  },
  {
      "service": "iptv",
      "title": "100% LOBO",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/195135.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/195135.mp4",
      "id": "",
      "author": "100% LOBO",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/n1oFUoYmj4JuJl5T417VQnZNCgB.jpg",
      "group": "INFANTIL"
  },
  {
      "service": "iptv",
      "title": "101 DALMATAS  ",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/174880.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/174880.mp4",
      "id": "",
      "author": "101 DALMATAS  ",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/fz6rT9M05abWXlU964qJ151On47.jpg",
      "group": "CLASSICO"
  },
  {
      "service": "iptv",
      "title": "10X10",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/65221.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/65221.mp4",
      "id": "",
      "author": "10X10",
      "thumb_square": "http://pbh.vc:80/images/egMETBYual2JtfFGigXTA0tGkME_small.jpg",
      "group": "TERROR/SUSPENSE"
  },
  {
      "service": "iptv",
      "title": "12 ANOS DE ESCRAVIDÃO",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/170671.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/170671.mp4",
      "id": "",
      "author": "12 ANOS DE ESCRAVIDÃO",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/6RUTPOAB4rRlrRYFMGjvK6bm1x8.jpg",
      "group": "ROMANCE/DRAMA"
  },
  {
      "service": "iptv",
      "title": "12 FEET DEEP LEG",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/232209.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/232209.mp4",
      "id": "",
      "author": "12 FEET DEEP LEG",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/3B4MLYYr5WTjQiJMbA8fCFF8M4O.jpg",
      "group": "LEGENDADO"
  },
  {
      "service": "iptv",
      "title": "12 HORAS PARA SOBREVIVER: O ANO DA ELEIÇÃO",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/53164.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/53164.mp4",
      "id": "",
      "author": "12 HORAS PARA SOBREVIVER: O ANO DA ELEIÇÃO",
      "thumb_square": "http://pbh.vc:80/images/sm7p6NvWOBSDywdIPhmiX3SLSH8_small.jpg",
      "group": "TERROR/SUSPENSE"
  },
  {
      "service": "iptv",
      "title": "12 ORFAOS PODEROSOS",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/214794.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/214794.mp4",
      "id": "",
      "author": "12 ORFAOS PODEROSOS",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/3N0nM4ouA500Xp5uSMpVazLNF0c.jpg",
      "group": "ROMANCE/DRAMA"
  },
  {
      "service": "iptv",
      "title": "13 DISTRITO",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/230836.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/230836.mp4",
      "id": "",
      "author": "13 DISTRITO",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/fJwAFbjWXdbu0nl8wZPYn1VZkNj.jpg",
      "group": "ACAO/AVENTURA"
  },
  {
      "service": "iptv",
      "title": "13 DISTRITO ULTIMATO",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/230835.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/230835.mp4",
      "id": "",
      "author": "13 DISTRITO ULTIMATO",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/97eKX9vZeHk6ugHO8jFToaNVkQY.jpg",
      "group": "ACAO/AVENTURA"
  },
  {
      "service": "iptv",
      "title": "13 FANTASMAS",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/230390.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/230390.mp4",
      "id": "",
      "author": "13 FANTASMAS",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/rVcigGnXNtYQoBAhTWGAS9o03jc.jpg",
      "group": "TERROR/SUSPENSE"
  },
  {
      "service": "iptv",
      "title": "13 MINUTOS",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/170638.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/170638.mp4",
      "id": "",
      "author": "13 MINUTOS",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/tQqjEmJEgetLMBLArWPVvyFBbZ4.jpg",
      "group": "GUERRA"
  },
  {
      "service": "iptv",
      "title": "13 O MUSICAL",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/236572.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/236572.mp4",
      "id": "",
      "author": "13 O MUSICAL",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/3ndpRWh8iOvckVtHptIsD4OtayN.jpg",
      "group": "ROMANCE/DRAMA"
  },
  {
      "service": "iptv",
      "title": "15 MINUTOS DE GUERRA",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/65410.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/65410.mp4",
      "id": "",
      "author": "15 MINUTOS DE GUERRA",
      "thumb_square": "http://pbh.vc:80/images/aOxDHnalXbzZdjIeL2XbpeTAtOZ_small.jpg",
      "group": "GUERRA"
  },
  {
      "service": "iptv",
      "title": "15H17 TREM PARA PARIS",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/5795.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/5795.mp4",
      "id": "",
      "author": "15H17 TREM PARA PARIS",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/zYf02uVwiXdUEQVX9TuIizQIdHA.jpg",
      "group": "ROMANCE/DRAMA"
  },
  {
      "service": "iptv",
      "title": "16 QUADRAS",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/242186.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/242186.mp4",
      "id": "",
      "author": "16 QUADRAS",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/tbWd6N4ORhDJxlMVO4vxRNEZtib.jpg",
      "group": "ACAO/AVENTURA"
  },
  {
      "service": "iptv",
      "title": "17 GAROTAS",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/215662.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/215662.mp4",
      "id": "",
      "author": "17 GAROTAS",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/dNVWfa9szO5X1hWHIWKliRFr68E.jpg",
      "group": "ROMANCE/DRAMA"
  },
  {
      "service": "iptv",
      "title": "18 PRESENTES",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/242205.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/242205.mp4",
      "id": "",
      "author": "18 PRESENTES",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/xvXSGXTN18Lvp3sGoFpzLgwyoOV.jpg",
      "group": "ROMANCE/DRAMA"
  },
  {
      "service": "iptv",
      "title": "18 PRESENTES LEG  ",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/180123.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/180123.mp4",
      "id": "",
      "author": "18 PRESENTES LEG  ",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/yMVJWGtxNHCU6kl58f8o4BTyrKz.jpg",
      "group": "LEGENDADO"
  },
  {
      "service": "iptv",
      "title": "1917",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/168586.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/168586.mp4",
      "id": "",
      "author": "1917",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/p6lDTyJl7SEbv4jZkftVBPndZH2.jpg",
      "group": "GUERRA"
  },
  {
      "service": "iptv",
      "title": "1964 O BRASIL ENTRE ARMAS E LIVROS",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/242204.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/242204.mp4",
      "id": "",
      "author": "1964 O BRASIL ENTRE ARMAS E LIVROS",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/kERgnDxb4YXU0c6DDSWZiLmuHC1.jpg",
      "group": "DOCUMENTARIO"
  },
  {
      "service": "iptv",
      "title": "2 22 ENCONTRO MARCADO",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/242206.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/242206.mp4",
      "id": "",
      "author": "2 22 ENCONTRO MARCADO",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/t8uRzSCDenLoMZ8TJni9SAbif5S.jpg",
      "group": "ROMANCE/DRAMA"
  },
  {
      "service": "iptv",
      "title": "20 ANOS MAIS JOVEM",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/242193.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/242193.mp4",
      "id": "",
      "author": "20 ANOS MAIS JOVEM",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/jsGdeuI0KrCqZd768FMIQHNySdn.jpg",
      "group": "COMEDIA"
  },
  {
      "service": "iptv",
      "title": "2000 MULES LEG",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/231038.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/231038.mp4",
      "id": "",
      "author": "2000 MULES LEG",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/nTz0ZDc2PdHpn07O37Jmq2vaSPV.jpg",
      "group": "LEGENDADO"
  },
  {
      "service": "iptv",
      "title": "2001 UMA ODISSEIA NO ESPACO",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/241938.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/241938.mp4",
      "id": "",
      "author": "2001 UMA ODISSEIA NO ESPACO",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/66cLRuqg7dLXRgvgk427leeZhjd.jpg",
      "group": "FICCAO"
  },
  {
      "service": "iptv",
      "title": "2012",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/51656.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/51656.mp4",
      "id": "",
      "author": "2012",
      "thumb_square": "http://pbh.vc:80/images/zf1idF1ys8zuaAzEEzghre5A4m3_small.jpg",
      "group": "FICCAO"
  },
  {
      "service": "iptv",
      "title": "2012 ",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/242216.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/242216.mp4",
      "id": "",
      "author": "2012 ",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/6ZwQWTKeLfmGyaCxKWEqEM7Ewtq.jpg",
      "group": "FICCAO"
  },
  {
      "service": "iptv",
      "title": "2036 ORIGEM DESCONHECIDA",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/242215.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/242215.mp4",
      "id": "",
      "author": "2036 ORIGEM DESCONHECIDA",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/v3StJTYXbU3OZ26Vh1PbczqfyCD.jpg",
      "group": "FICCAO"
  },
  {
      "service": "iptv",
      "title": "2067",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/201794.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/201794.mp4",
      "id": "",
      "author": "2067",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/7D430eqZj8y3oVkLFfsWXGRcpEG.jpg",
      "group": "FICCAO"
  },
  {
      "service": "iptv",
      "title": "2067",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/241934.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/241934.mp4",
      "id": "",
      "author": "2067",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/7D430eqZj8y3oVkLFfsWXGRcpEG.jpg",
      "group": "FICCAO"
  },
  {
      "service": "iptv",
      "title": "211: O GRANDE ASSALTO",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/22684.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/22684.mp4",
      "id": "",
      "author": "211: O GRANDE ASSALTO",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/nGvFQ1pB3YebdXYY0IsQfEIzm1C.jpg",
      "group": "ACAO/AVENTURA"
  },
  {
      "service": "iptv",
      "title": "22 MILHAS",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/45813.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/45813.mp4",
      "id": "",
      "author": "22 MILHAS",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/fcHq8q0cW49YlACLhwtknMtBv23.jpg",
      "group": "ACAO/AVENTURA"
  },
  {
      "service": "iptv",
      "title": "3 NINJAS",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/232159.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/232159.mp4",
      "id": "",
      "author": "3 NINJAS",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/KaUfT7TvsZt26OwBqmbPiMxvhO.jpg",
      "group": "ACAO/AVENTURA"
  },
  {
      "service": "iptv",
      "title": "3 NINJAS EM APUROS",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/232160.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/232160.mp4",
      "id": "",
      "author": "3 NINJAS EM APUROS",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/grCDfYf3GNaiQ74plEGmvyKUFrF.jpg",
      "group": "ACAO/AVENTURA"
  },
  {
      "service": "iptv",
      "title": "3 TURCOS E UM BEBÊ",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/25694.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/25694.mp4",
      "id": "",
      "author": "3 TURCOS E UM BEBÊ",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/9w1OgDXR2GhHxJuFkAp4UD1Pzwk.jpg",
      "group": "COMEDIA"
  },
  {
      "service": "iptv",
      "title": "30 DIAS DE NOITE",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/172492.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/172492.mp4",
      "id": "",
      "author": "30 DIAS DE NOITE",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/tbG7VEn5dZZQAjSNCPMAHzpf4GQ.jpg",
      "group": "TERROR/SUSPENSE"
  },
  {
      "service": "iptv",
      "title": "30 DIAS DE NOITE SOMBRIA 2",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/172493.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/172493.mp4",
      "id": "",
      "author": "30 DIAS DE NOITE SOMBRIA 2",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/bXHaXVGncI2WKUrNRYDyTyj3ymR.jpg",
      "group": "TERROR/SUSPENSE"
  },
  {
      "service": "iptv",
      "title": "300",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/18375.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/18375.mp4",
      "id": "",
      "author": "300",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/dFXb30biVItPuNY3scjbcZ70oxD.jpg",
      "group": "GUERRA"
  },
  {
      "service": "iptv",
      "title": "300: A ASSENÇÃO DO IMPÉRIO",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/18376.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/18376.mp4",
      "id": "",
      "author": "300: A ASSENÇÃO DO IMPÉRIO",
      "thumb_square": "https://media.fstatic.com/UasJe79UdBh_k45d4pTgP0KouLY=/fit-in/290x478/smart/media/movies/covers/2013/06/300-a-ascensao-de-um-imperio_t23336.jpg",
      "group": "ACAO/AVENTURA"
  },
  {
      "service": "iptv",
      "title": "303 SQUADRON (LEG)",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/164095.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/164095.mp4",
      "id": "",
      "author": "303 SQUADRON (LEG)",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/wsc5ZDQUOiHAC06FeWW4y9jnaOH.jpg",
      "group": "LEGENDADO"
  },
  {
      "service": "iptv",
      "title": "36 HORAS DE GUERRA",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/242207.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/242207.mp4",
      "id": "",
      "author": "36 HORAS DE GUERRA",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/eRUrER9xFIiLp2aMIJjCTEOU3VX.jpg",
      "group": "ROMANCE/DRAMA"
  },
  {
      "service": "iptv",
      "title": "365 DIAS",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/172686.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/172686.mp4",
      "id": "",
      "author": "365 DIAS",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/3b1uR8Jggt1hWgtBbp42Mt3cwWe.jpg",
      "group": "ROMANCE/DRAMA"
  },
  {
      "service": "iptv",
      "title": "365 DIAS (LEG)",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/171143.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/171143.mp4",
      "id": "",
      "author": "365 DIAS (LEG)",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/ibaDBJBAvWd4J5lzfbpq1PJ6ZU1.jpg",
      "group": "LEGENDADO"
  },
  {
      "service": "iptv",
      "title": "365 DIAS FINAIS",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/232541.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/232541.mp4",
      "id": "",
      "author": "365 DIAS FINAIS",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/2pxKwxMbd5l3BxK5fBgVCWYweH2.jpg",
      "group": "ROMANCE/DRAMA"
  },
  {
      "service": "iptv",
      "title": "365 DIAS HOJE",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/225888.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/225888.mp4",
      "id": "",
      "author": "365 DIAS HOJE",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/67vb4sQxJ06o4LvgoxB8Fj6mGqk.jpg",
      "group": "ROMANCE/DRAMA"
  },
  {
      "service": "iptv",
      "title": "4 LATAS",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/67429.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/67429.mp4",
      "id": "",
      "author": "4 LATAS",
      "thumb_square": "http://pbh.vc:80/images/hxtvnvWdeibiDc6mU5ftAUPGofe_small.jpg",
      "group": "COMEDIA"
  },
  {
      "service": "iptv",
      "title": "40 DIAS O MILAGRE DA VIDA",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/190582.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/190582.mp4",
      "id": "",
      "author": "40 DIAS O MILAGRE DA VIDA",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/kyoSXlgV8OMGyAfBHwyDECp1m5f.jpg",
      "group": "ROMANCE/DRAMA"
  },
  {
      "service": "iptv",
      "title": "400 CONTRA 1 UMA HISTORIA DO CRIME ORGANIZADO",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/240269.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/240269.mp4",
      "id": "",
      "author": "400 CONTRA 1 UMA HISTORIA DO CRIME ORGANIZADO",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/dEUA5chjSbr8rTJprwiRO8Le1gT.jpg",
      "group": "NACIONAL"
  },
  {
      "service": "iptv",
      "title": "47 RONINS",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/170680.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/170680.mp4",
      "id": "",
      "author": "47 RONINS",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/pKM4v6dZ1LSKzRQxN0YEbmCKGAH.jpg",
      "group": "ACAO/AVENTURA"
  },
  {
      "service": "iptv",
      "title": "4X100: CORRENDO POR UM SONHO",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/213923.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/213923.mp4",
      "id": "",
      "author": "4X100: CORRENDO POR UM SONHO",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/1PpcsIX67bcZas5aKfM3yOFPDVN.jpg",
      "group": "COMEDIA"
  },
  {
      "service": "iptv",
      "title": "5 DIAS DE GUERRA",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/170639.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/170639.mp4",
      "id": "",
      "author": "5 DIAS DE GUERRA",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/hnMThO1o6zyKAY0C3LUmccVg7SU.jpg",
      "group": "GUERRA"
  },
  {
      "service": "iptv",
      "title": "6 DIAS",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/22053.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/22053.mp4",
      "id": "",
      "author": "6 DIAS",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/yRbC0uRj1RDmF0JLon83il2dkR0.jpg",
      "group": "ACAO/AVENTURA"
  },
  {
      "service": "iptv",
      "title": "60 SEGUNDOS",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/232166.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/232166.mp4",
      "id": "",
      "author": "60 SEGUNDOS",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/1OUMxMVTPgQCyxB4DzIm63MDgT5.jpg",
      "group": "ACAO/AVENTURA"
  },
  {
      "service": "iptv",
      "title": "7 DENÚNCIAS: AS CONSEQUÊNCIAS DO CASO COVID-19",
      "playlistURL": "https://x2.rs/kZzcT",
      "media_url": "http://cdnrede.com:80/movie/Isadora/141917/171739.mp4",
      "url": "http://cdnrede.com:80/movie/Isadora/141917/171739.mp4",
      "id": "",
      "author": "7 DENÚNCIAS: AS CONSEQUÊNCIAS DO CASO COVID-19",
      "thumb_square": "https://image.tmdb.org/t/p/w600_and_h900_bestv2/h8TMe2p5ENCuIb0tJRb21NRqiHU.jpg",
      "group": "DOCUMENTARIO"
  }
]

  constructor() { }
}
