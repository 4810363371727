import { Injectable } from "@angular/core";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
@Injectable({
  providedIn: "root",
})
export class AnalysticsService {
  constructor() {}

  initializeFirebase() {
    /**
     * Platform: Web
     * Configure and initialize the firebase app.
     * @param options - firebase web app configuration options
     * */
    FirebaseAnalytics.initializeFirebase({
      apiKey: "AIzaSyBYnzW5L9kPl3JbxHNGcuolCIjoGuZqfqw",
      authDomain: "iptv-7998a.firebaseapp.com",
      projectId: "iptv-7998a",
      storageBucket: "iptv-7998a.appspot.com",
      messagingSenderId: "385931471358",
      appId: "1:385931471358:web:9e68568f1a413b36e2b8af",
      measurementId: "G-NTPPHMDZ0H",
    });
  }

  setUserId(uuid: string) {
    /**
     * Platform: Web/Android/iOS
     * Sets the user ID property.
     * @param userId - unique identifier of a user
     * @returns void
     * https://firebase.google.com/docs/analytics/userid
     */
    FirebaseAnalytics.setUserId({
      userId: uuid,
    });
  }

  setUserProperty() {
    /**
     * Platform: Web/Android/iOS
     * Sets a user property to a given value.
     * @param options - property name and value to set
     * @returns void
     * https://firebase.google.com/docs/analytics/user-properties
     */
    FirebaseAnalytics.setUserProperty({
      name: "favorite_food",
      value: "pizza",
    });
  }

  getAppInstanceId() {
    /**
     * Platform: Android/iOS
     * Retrieves the app instance id from the service.
     * @param none
     * @returns instanceId - individual instance id value
     * https://firebase.google.com/docs/analytics/user-properties
     */
    FirebaseAnalytics.getAppInstanceId();
  }

  setScreenName(name: string, nameScreen: string) {
    /**
     * Platform: Android/iOS
     * Sets the current screen name, which specifies the current visual context in your app.
     * @param screenName - name of the current screen to track
     *        nameOverride - name of the screen class to override
     * @returns instanceId - individual instance id value
     * https://firebase.google.com/docs/analytics/screenviews
     */
    FirebaseAnalytics.setScreenName({
      screenName: name,
      nameOverride: nameScreen,
    });
  }

  reset() {
    /**
     * Platform: Web/Android/iOS
     * Clears all analytics data for this app from the device and resets the app instance id.
     * @param none
     * @returns void
     */
    FirebaseAnalytics.reset();
  }

  logEvent(name: string, content_id: string, items: Array<any>) {
    /**
     * Platform: Web/Android/iOS
     * Logs an app event.
     * @param name - name of the event to log
     *        params - key/value pairs of properties (25 maximum per event)
     * @returns void
     */
    FirebaseAnalytics.logEvent({
      name: name,
      params: {
        /*  content_type: "image", */
        content_id: content_id,
        items: items,
      },
    });
  }

  setCollectionEnabled() {
    /**
     * Platform: Web/Android/iOS
     * Sets whether analytics collection is enabled for this app on this device.
     * @param name - enabled - boolean true/false
     * @returns void
     */
    FirebaseAnalytics.setCollectionEnabled({
      enabled: false,
    });
  }

  enable() {
    /**
     * Platform: Web/Android/iOS
     * Deprecated - use setCollectionEnabled() instead
     * Enable analytics collection for this app on this device.
     * @param none
     * @returns void
     */
    FirebaseAnalytics.enable();
  }

  disable() {
    /**
     * Platform: Web/Android/iOS
     * Deprecated - use setCollectionEnabled() instead
     * Disable analytics collection for this app on this device.
     * @param none
     * @returns void
     */
    FirebaseAnalytics.disable();
  }

  setSessionTimeoutDuration() {
    /**
     * Platform: Web/Android/iOS
     * Sets the duration of inactivity that terminates the current session.
     * @param duration - duration in seconds (default - 18000)
     * @returns void
     */
    FirebaseAnalytics.setSessionTimeoutDuration({
      duration: 10000,
    });
  }
}
