import { CreateIptv, ListIPTV } from "./../interface/create-iptv";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ListService {
  constructor() {}

  verifyList(array: CreateIptv) {
    var value: CreateIptv[] =
      JSON.parse(localStorage.getItem("listIPTV")) ?? null;
    value = value ?? null;
    if (value == null) return this.setList([array]);
    var list: any = [];
    if (!Array.isArray(value)) {
      list = list.concat(value);
    } else {
      list = value;
    }

    list = list.concat(array);
    this.setList(list);
  }

  removeList(array: CreateIptv) {
    var value: CreateIptv[] =
      JSON.parse(localStorage.getItem("listIPTV")) ?? null;
    value = value ?? null;
    const resultList = value.findIndex((element, index) => {
      if (element.url === array.url) {
        return true;
      }
    });
    console.log(resultList, value, array.url);
    if (resultList > -1) {
      value.splice(resultList, 1);
      this.setList(value);
      return;
    }
  }

  updateList(array: CreateIptv, origin: string) {
    var value: CreateIptv[] =
      JSON.parse(localStorage.getItem("listIPTV")) ?? null;
    value = value ?? null;
    const resultList = value.findIndex((element, index) => {
      if (element.url === origin) {
        return true;
      }
    });
    if (resultList > -1) {
      value[resultList] = {
        name: array.name,
        url: array.url,
      };
      this.setList(value);
      return;
    }
  }

  joinData(array: CreateIptv, dataLoading: ListIPTV) {
    var value: CreateIptv[] =
      JSON.parse(localStorage.getItem("listIPTV")) ?? null;
    value = value ?? null;
    const resultList = value.findIndex((element, index) => {
      if (element.url === array.url) {
        return true;
      }
    });

    if (resultList > -1) {
      value[resultList] = {
        name: array.name,
        url: array.url,
        data: dataLoading,
      };
      console.log(value[resultList]);
      this.setList(value);
      return;
    }
  }

  setList(array: any) {
    localStorage.setItem("listIPTV", JSON.stringify(array));
  }
}
