import { ServicesService } from "src/app/shared/services/services.service";
import { Component } from "@angular/core";
import { Device } from "@capacitor/device";
@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  constructor(private service: ServicesService) {
    this.initSystem();
  }

  initSystem() {
    /* init Analystics */
    this.initAnalystics();

    /* INFO CELL */
    this.startTlm();
    this.uuidTlm();
    this.setServiceTranslate();

    /* Init Language */
    this.getUsersLocale("en-us");

    /* init admob */
    this.service.admob.initialize;

    /* init Notification */
    this.initNotification();

    /* init Notification */
    this.initNoAds();

    /* init fcm */
    this.initFCM();

    /* init version */
    this.initVersion();
  }

  async startTlm() {
    const info = await Device.getInfo();
    localStorage.setItem("infoCELL", JSON.stringify(info));
  }

  async uuidTlm() {
    const info = await Device.getId();
    localStorage.setItem("uuidCELL", JSON.stringify(info));

    /* Set name Analystics */

    this.service.analystcs.setUserId(info.uuid);
  }

  setServiceTranslate() {
    this.service.translateService.manuallyTranslate();
  }

  getUsersLocale(defaultValue?: string): string {
    if (
      typeof window === "undefined" ||
      typeof window.navigator === "undefined"
    ) {
      return defaultValue;
    }
    const wn = window.navigator as any;
    let lang = wn.languages ? wn.languages[0] : defaultValue;
    lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;
    this.setLanguage(lang);
  }

  setLanguage(lang: string) {
    var language = localStorage.getItem("translateAPPSetting");
    localStorage.setItem("locale_kerboardAPP", lang);
    if (["pt", "en", "es"].includes(language)) return;

    switch (lang.toUpperCase()) {
      case "PT-PT":
      case "PT-BR":
        localStorage.setItem("translateAPPSetting", "pt");
        break;
      case "ES-ES":
      case "ES ES":
        localStorage.setItem("translateAPPSetting", "es");
        break;
      default:
        localStorage.setItem("translateAPPSetting", "en");
        break;
    }
  }

  /* not */

  initNotification() {
    var notification = localStorage.getItem("notificationAPPSetting");
    if (["pt", "en", "es"].includes(notification)) return;
    localStorage.setItem("notificationAPPSetting", "1");
  }

  initNoAds() {
    var ads = localStorage.getItem("noADSAPP");
    if (["0", "1"].includes(ads)) return;
    localStorage.setItem("noADSAPP", "1");
  }

  initFCM() {
    var fcm = localStorage.getItem("fcmAPP");
    if (fcm && fcm.length > 1) return;
    localStorage.setItem(
      "fcmAPP",
      "00000000000000000000000000000000000000000000"
    );

    this.service.fcm.initFCM();
  }

  initVersion() {
    localStorage.setItem("versionAPPIPTV", "0.0.1");
  }

  async initAnalystics() {
    const info = JSON.parse(localStorage.getItem("infoCELL"));
    if (info.platform === "web")
      return this.service.analystcs.initializeFirebase();
  }
}
