import { Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor() { }


  format_decimal(valor) {
    valor = valor.replace(/\D/g,"")

    let v = (valor/100).toFixed(2) + "";

    v = v.replace(".", ",");

    v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");

    v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");

    return v;
  }

  formatCurrencyMoney(type, valor, currency?) {
    /* BRL, USD, EUR */
    if (type) return valor.toLocaleString('pt-BR', { style: 'currency', currency: currency });
    //Valor sem cifrão	
    if (!type) return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
  }

  cpf(v) {
    v = "" + v.detail.value + ""
    v = v.replace(/\D/g, "")                    //Remove tudo o que não é dígito
    v = v.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
    //de novo (para o segundo bloco de números)
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2") //Coloca um hífen entre o terceiro e o quarto dígitos
    return v
  }

  cnpj(v) {
    v = "" + v.detail.value + ""
    v = v.replace(/\D/g, "")                           //Remove tudo o que não é dígito
    v = v.replace(/^(\d{2})(\d)/, "$1.$2")             //Coloca ponto entre o segundo e o terceiro dígitos
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
    v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
    v = v.replace(/(\d{4})(\d)/, "$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
    return v
  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  validateCPF(cpf) {
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf == '') return false;
    // Elimina CPFs invalidos conhecidos    
    if (cpf.length != 11 ||
      cpf == "00000000000" ||
      cpf == "11111111111" ||
      cpf == "22222222222" ||
      cpf == "33333333333" ||
      cpf == "44444444444" ||
      cpf == "55555555555" ||
      cpf == "66666666666" ||
      cpf == "77777777777" ||
      cpf == "88888888888" ||
      cpf == "99999999999")
      return false;
    // Valida 1o digito 
    var add = 0;
    for (var i = 0; i < 9; i++)
      add += parseInt(cpf.charAt(i)) * (10 - i);
    var rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
      rev = 0;
    if (rev != parseInt(cpf.charAt(9)))
      return false;
    // Valida 2o digito 
    add = 0;
    for (var i = 0; i < 10; i++)
      add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
      rev = 0;
    if (rev != parseInt(cpf.charAt(10)))
      return false;
    return true;
  }

  validarCNPJ(cnpj) {

    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;

    if (cnpj.length != 14)
      return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999")
      return false;

    // Valida DVs
    var tamanho = cnpj.length - 2
    var numeros = cnpj.substring(0, tamanho);
    var digitos = cnpj.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
   var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
      return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
      return false;

    return true;

  }

  nameAndFirstName(str) {
    var arr = str.split(0, 20);
    if (arr[1].toLowerCase() == 'de' || arr[1].toLowerCase() == 'da' || arr[1].toLowerCase() == 'do') {
      return arr[0] + " " + arr[1] + " " + arr[2]
    } else {
      return arr[0] + " " + arr[1]
    }
  }

  removeCaracter(name) {
    if (name.length > 20) { //verifica se o nome pessui mais de 16 caracteres
      name = name.substr(0, 20); // transformar em apenas 16 caracteres
      let aux = name.split(" "); // separa cada nome em um arrau
      if (aux[aux.length - 1].length <= 3) { // verifica se o ultimo nome tem mais de 3 caracteres
        aux.splice(aux.length - 1, 1); // remove o ultimo nome
      }
      return name = aux.join(' ') + "..."; // transforma o array em string novamente
    } else {
      return name
    }
  }


  telephoneBR(v) {
    v = v.detail.value;
    if (v.length <= 12) {
      v = v.replace(/\D/g, "")
      v = v.replace(/^(\d\d)(\d)/g, "($1) $2")
      v = v.replace(/(\d{4})(\d)/, "$1-$2")
      return v
    } else {
      v = v.replace(/\D/g, "")
      v = v.replace(/^(\d\d)(\d)/g, "($1) $2")
      v = v.replace(/(\d{5})(\d)/, "$1-$2")
      return v
    }
  }

  telephoneBRInput(v) {
    var setNumber = document.querySelector('[name="phone"]')
    v = v.detail.value;
    if (v.length <= 12) {
      v = v.replace(/\D/g, "")
      v = v.replace(/^(\d\d)(\d)/g, "($1) $2")
      v = v.replace(/(\d{4})(\d)/, "$1-$2")
      setNumber?.setAttribute('value', v)
    } else {
      v = v.replace(/\D/g, "")
      v = v.replace(/^(\d\d)(\d)/g, "($1) $2")
      v = v.replace(/(\d{5})(\d)/, "$1-$2")
      setNumber?.setAttribute('value', v)
    }
  }

  async openCapacitorSite(url) {
    url: String(url);
    await Browser.open({ url: url });
  };

  formatCep(val: string) {
    val = val.replace(/\D/g,"");
    val = val.replace(/^(\d{5})(\d)/,"$1-$2")

    return val;
  }


  formatHour(val: string) {
    val = val.replace(/\D/g, "");
    val = val.replace(/^(\d{2})(\d)/, "$1:$2")

    return val;
  }

  showFormErrors(form: any): void {
    Object.keys(form.controls).forEach(key => {

      const controlErrors: any = form.get(key).errors;
      if (controlErrors) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log({
            'control': key,
            'error': keyError,
            'value': controlErrors[keyError]
          });
        });
      }
    });
  }

}

