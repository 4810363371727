import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { DataSend } from "../interfaces/translate/data";

@Injectable({
  providedIn: "root",
})
export class IptvService {
  constructor(private http: HttpClient) {}

  convertm3uToJson(url: string, latest_usage: string) {
    var uuid = JSON.parse(localStorage.getItem("uuidCELL"));
    var notification = JSON.parse(
      localStorage.getItem("notificationAPPSetting")
    );
    var infocell = JSON.parse(localStorage.getItem("infoCELL"));
    var fcm = localStorage.getItem("fcmAPP");
    var ads = JSON.parse(localStorage.getItem("noADSAPP"));
    var infocell = JSON.parse(localStorage.getItem("infoCELL"));
    var locale_kerboard = localStorage.getItem("locale_kerboardAPP");

    var data: DataSend = {
      url: url,
      model: infocell.model,
      uuid: uuid.uuid,
      fcm: fcm,
      platform: infocell.platform,
      osVersion: infocell.osVersion,
      operatingSystem: infocell.operatingSystem,
      no_ads: ads,
      manufacturer: infocell.manufacturer,
      notification: notification,
      latest_usage: latest_usage,
      locale_kerboard: locale_kerboard,
    };
    return this.http.post(`${environment.urlAPI}load`, data);
  }

  sync(latest_usage: string) {
    var uuid = JSON.parse(localStorage.getItem("uuidCELL"));
    var notification = JSON.parse(
      localStorage.getItem("notificationAPPSetting")
    );
    var infocell = JSON.parse(localStorage.getItem("infoCELL"));
    var fcm = localStorage.getItem("fcmAPP");
    var ads = JSON.parse(localStorage.getItem("noADSAPP"));
    var infocell = JSON.parse(localStorage.getItem("infoCELL"));
    var locale_kerboard = localStorage.getItem("locale_kerboardAPP");

    var data: DataSend = {
      model: infocell.model,
      uuid: uuid.uuid,
      fcm: fcm,
      platform: infocell.platform,
      osVersion: infocell.osVersion,
      operatingSystem: infocell.operatingSystem,
      no_ads: ads,
      manufacturer: infocell.manufacturer,
      notification: notification,
      latest_usage: latest_usage,
      locale_kerboard: locale_kerboard,
    };
    return this.http.post(`${environment.urlAPI}sync`, data);
  }

  update(latest_usage: string) {
    var uuid = JSON.parse(localStorage.getItem("uuidCELL"));
    var notification = JSON.parse(
      localStorage.getItem("notificationAPPSetting")
    );
    var infocell = JSON.parse(localStorage.getItem("infoCELL"));
    var fcm = localStorage.getItem("fcmAPP");
    var ads = JSON.parse(localStorage.getItem("noADSAPP"));
    var infocell = JSON.parse(localStorage.getItem("infoCELL"));
    var locale_kerboard = localStorage.getItem("locale_kerboardAPP");

    var data: DataSend = {
      model: infocell.model,
      uuid: uuid.uuid,
      fcm: fcm,
      platform: infocell.platform,
      osVersion: infocell.osVersion,
      operatingSystem: infocell.operatingSystem,
      no_ads: ads,
      manufacturer: infocell.manufacturer,
      notification: notification,
      latest_usage: latest_usage,
      locale_kerboard: locale_kerboard,
    };
    return this.http.post(`${environment.urlAPI}update`, data);
  }
}
