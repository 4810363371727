import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class WatchingService {
  constructor() {}

  verifyWatching(array: any) {
    var value = JSON.parse(localStorage.getItem("watchingIPTV")) ?? null;
    value = value ?? null;
    if (value == null) return this.setWatching([array]);
    var list: any = [];

    if (!Array.isArray(value)) {
      list = list.concat(value);
    } else {
      list = value;
    }

    const resultList = value.findIndex((element, index) => {
      if (element.url === array.url) {
        return true;
      }
    });
    if (resultList > -1) {
      list = this.changePosition(list, resultList, list.length - 1);
      this.setWatching(list);
      return;
    }

    list = list.concat(array);
    this.setWatching(list);
  }

  changePosition(arr, from, to) {
    arr.splice(to, 0, arr.splice(from, 1)[0]);
    return arr;
  }

  setWatching(array: any) {
    localStorage.setItem("watchingIPTV", JSON.stringify(array));
  }

  joinWatching(data: any) {
    var value = JSON.parse(localStorage.getItem("watchingIPTV")) ?? null;
    value = value ?? null;
    if (value == null) return data;

    var result = data.map((screen) => ({
      ...value.find((o) => o.url == screen.url),
      ...screen,
    }));

    return result;
  }
}
