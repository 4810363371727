import { ListIPTV } from "./../../modules/list-Iptv/interface/create-iptv";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  constructor() {}

  setStorage(name: string, array: any) {
    localStorage.setItem(name, JSON.stringify(array));
  }

  getStorage(name: string, params: string) {
    var value = JSON.parse(localStorage.getItem(name));

    if (value && params) {
      value = value.filter((filter) => {
        return filter.playlistURL === params;
      });
    }

    return value ?? null;
  }

  setStorageStar(name: string, array: any) {
    var value = JSON.parse(localStorage.getItem(name)) ?? null;
    value = value ?? null;
    if (!value) return this.setStorage(name, array);
    this.updateStorageStar(name, array, value);
  }

  updateStorageStar(name: string, array: any, value: any) {
    var list: any = [];

    if (!Array.isArray(value)) {
      list = list.concat(value);
    } else {
      list = value.slice(0, 10);
    }
    const resultList = value.findIndex((element, index) => {
      if (element.url === array.url) {
        return true;
      }
    });

    if (resultList > -1) {
      list.splice(resultList, 1);
      list = list.concat(array);
      this.setStorage(name, list);
      return;
    }

    list = list.concat(array);
    this.setStorage(name, list);
  }
}
