import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FavoritesService {
  constructor() {}

  verifyFavorite(array: any) {
    array.favorite = true;
    var value = JSON.parse(localStorage.getItem("FavoritesIptv")) ?? null;
    value = value ?? null;
    if (value == null) return this.setFavorite([array]);
    var list: any = [];

    if (!Array.isArray(value)) {
      list = list.concat(value);
    } else {
      list = value;
    }

    const resultList = value.findIndex((element, index) => {
      if (element.url === array.url) {
        return true;
      }
    });
    if (resultList > -1) {
      array.favorite = false;
      list.splice(resultList, 1);
      console.log(list, resultList);
      this.setFavorite(list);
      return;
    }

    list = list.concat(array);
    this.setFavorite(list);
  }

  setFavorite(array: any) {
    localStorage.setItem("FavoritesIptv", JSON.stringify(array));
  }

  joinFavorite(data: any) {
    var value = JSON.parse(localStorage.getItem("FavoritesIptv")) ?? null;
    value = value ?? null;
    if (value == null) return data;

    var result = data.map((screen) => ({
      ...value.find((o) => o.url == screen.url),
      ...screen,
    }));

    return result;
  }
}
