import { AnalysticsService } from './analystics.service';
import { FcmService } from './fcm.service';
import { MomentjsService } from './momentjs.service';
import { WatchingService } from './../../modules/home/services/watching.service';
import { AdmobService } from './admob.service';
import { MockupsService } from './../mockups/mockups.service';
import { ListService } from "../../modules/list-Iptv/services/list.service";
import { TranslateService } from "./translate.service";
import { ToastService } from "./toast.service";
import { UtilitiesService } from "./utilities.service";
import { FavoritesService } from "./../../modules/home/services/favorites.service";
import { IptvService } from "./iptv.service";
import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";
import { Router } from "@angular/router";
import { Clipboard } from "@capacitor/clipboard";
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: "root",
})
export class ServicesService {
  loading: boolean = false;
  constructor(
    /* Plugins */
    public router: Router,
    public navCtrl:NavController,
    /* utils */
    public utilitiesService: UtilitiesService,
    public ToastService: ToastService,
    public translateService: TranslateService,
    public mockupsService: MockupsService,
    public moment:MomentjsService,
    /* Services */
    public iptvService: IptvService,
    public storageService: StorageService,
    public favoriteService: FavoritesService,
    public listService: ListService,
    public admob:AdmobService,
    public watchingService:WatchingService,
    public fcm:FcmService,
    public analystcs:AnalysticsService
  ) {}

  async copy(text: string) {
    await Clipboard.write({
      string: text,
    });
  }

  back(){
    this.navCtrl.back()
  }
}
