import { Injectable } from '@angular/core';
import * as moment from 'moment'

@Injectable({
  providedIn: 'root'
})
export class MomentjsService {

  constructor() { }

  momentSimpleDB(date: any) {
    return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm")
  }

  momentFormat(date?: any, typeDate?: any, format?: any, locale?: string, add?: number, addType?: any) {
    if (locale) moment.locale(locale);
    if (date && !add) return moment(date, typeDate).format(format)
    if (!date && !add) return moment().format(format)
    if (add && !date) return moment().add(add, addType).format(format)
    if (date && add) return moment(date, typeDate).add(add, addType).format(format)
  }

  momentEndOfMonth(date?: any, typeDate?: any, format?: any, locale?: string) {
    if (locale) moment.locale(locale);
    if (date) return moment(date, typeDate).endOf('month').format(format)
    if (!date) return moment().endOf('month').format(format)
  }

  momentStartOfMonth(date?: any, typeDate?: any, format?: any, locale?: string) {
    if (locale) moment.locale(locale);
    if (date) return moment(date, typeDate).startOf('month').format(format)
    if (!date) return moment().startOf('month').format(format)
  }

  momentRelativeTime(date?: any, typeDate?: any, locale?: string) {
    if (locale) moment.locale(locale);
    return parseInt(moment(date, typeDate).fromNow(true));
  }

  momentIsValid(date: any, typeDate) {
    let result = moment(date, typeDate, true).isValid();
    return result
  }
}
