import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routes: Routes = [
/*   {
    path: "",
    loadChildren: () => import('./modules/list-Iptv/pages/iptv-list/iptv-list.module').then( m => m.IptvListPageModule)
  }, */
  {
    path: "",
    loadChildren: () => import('./modules/web/page/web/web.module').then( m => m.WebPageModule)
  },
  {
    path: "login",
    loadChildren: () =>
      import("./modules/login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: "modal",
    loadChildren: () =>
      import("./modules/home/pages/components/modal/modal.module").then((m) => m.ModalPageModule),
  },
  {
    path: "info-modal",
    loadChildren: () =>
      import("./modules/info-modal/info-modal.module").then(
        (m) => m.InfoModalPageModule
      ),
  },
  {
    path: "home",
    loadChildren: () =>
      import("./modules/home/pages/Home/home.module").then(
        (m) => m.HomePageModule
      ),
  },
  {
    path: "search",
    loadChildren: () =>
      import("./modules/home/pages/search/search.module").then((m) => m.SearchPageModule),
  },
  {
    path: 'iptv',
    loadChildren: () => import('./modules/list-Iptv/pages/iptv-list/iptv-list.module').then( m => m.IptvListPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./modules/settings/page/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'web',
    loadChildren: () => import('./modules/web/page/web/web.module').then( m => m.WebPageModule)
  },

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
