import { Injectable } from "@angular/core";
import {
  AdLoadInfo,
  AdMob,
  AdMobBannerSize,
  AdOptions,
  BannerAdOptions,
  BannerAdPluginEvents,
  BannerAdPosition,
  BannerAdSize,
  InterstitialAdPluginEvents,
} from "@capacitor-community/admob";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class AdmobService {
  constructor() {}

  async initialize() {
    const { status } = await AdMob.trackingAuthorizationStatus();

    if (status === "notDetermined") {
      /**
       * If you want to explain TrackingAuthorization before showing the iOS dialog,
       * you can show the modal here.
       * ex)
       * const modal = await this.modalCtrl.create({
       *   component: RequestTrackingPage,
       * });
       * await modal.present();
       * await modal.onDidDismiss();  // Wait for close modal
       **/
    }

    AdMob.initialize({
      requestTrackingAuthorization: true,
      testingDevices: ["2077ef9a63d2b398840261c8221a0c9b"],
      initializeForTesting: true,
    });
  }

  banner() {
    AdMob.addListener(BannerAdPluginEvents.Loaded, () => {
      // Subscribe Banner Event Listener
    });

    AdMob.addListener(
      BannerAdPluginEvents.SizeChanged,
      (size: AdMobBannerSize) => {
        // Subscribe Change Banner Size
      }
    );

    const optionsDEBUG: BannerAdOptions = {
      adId: "ca-app-pub-9202516420987554/7647637682",
      adSize: BannerAdSize.BANNER,
      position: BannerAdPosition.BOTTOM_CENTER,
      margin: 0,
      isTesting: true,
      npa: true,
    };

    const options: BannerAdOptions = {
      adId: "ca-app-pub-9202516420987554/7647637682",
      adSize: BannerAdSize.BANNER,
      position: BannerAdPosition.BOTTOM_CENTER,
      margin: 0,
    };

    var admob = environment.production ? options : optionsDEBUG;
    AdMob.showBanner(admob);
  }

  async interstitial() {
    AdMob.addListener(
      InterstitialAdPluginEvents.Loaded,
      (info: AdLoadInfo) => {}
    );

    const optionsDEBUG: AdOptions = {
      adId: "ca-app-pub-9202516420987554/9559485156",
      isTesting: true,
      npa: true,
    };

    const options: AdOptions = {
      adId: "ca-app-pub-9202516420987554/9559485156",
    };

    var admob = environment.production ? options : optionsDEBUG;
    await AdMob.prepareInterstitial(admob);
    await AdMob.showInterstitial();
  }
}
